import React, { useEffect, useState } from "react";
import './ballon.css';
import axios from 'axios';
import toast from 'react-hot-toast';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import { Loading } from "../../../utils/Utilities";


export default function DetailReport({ allReportAux, closeReport, loadReport }) {
    const [closed, setclosed] = useState(allReportAux?.report?.closed === null ? false : true);
    const [report, setReport] = useState(allReportAux?.report);
    const [responses, setResponses] = useState(allReportAux?.responses);
    var resposeReportClean = { hashKey: allReportAux?.report?.hashKey, response: "" }
    const [responseReport, setResponseReport] = useState(resposeReportClean);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (allReportAux?.reportAux !== undefined) {
            setResponseReport({ ...responseReport, hashKey: allReportAux?.report?.hashKey });
        }
    }, [allReportAux])


    const handleChange = (event) => {
        const { name, value } = event.target;
        setResponseReport({ ...responseReport, [name]: value });
    }

    const makeResponse = (e) => {
        if (responseReport?.hashKey !== "" && responseReport?.hashKey !== undefined) {
            if (convertedContent !== "" && convertedContent !== undefined && convertedContent !== null) {
                setLoading(true);
                responseReport.response = convertedContent;
                axios.post(global.api_url + "Report/MakeReportResponse", responseReport)
                    .then((response) => {
                        if (response.status === 200) {
                            var newobj = response.data;
                            setResponses([...responses, newobj]);
                            setResponseReport(resposeReportClean);
                            setConvertedContent(null);
                            setEditorState(EditorState.createEmpty());
                            toast.success('Denuncia carregada com sucesso!', { position: 'top-right' });
                            setLoading(false);
                        }
                        setLoading(false);
                    }).catch(error => { setLoading(false); toast.error("Código inválido!", { position: 'top-right' }); })

            } else { toast.error("É necessáro escrever uma repsosta para submeter uma resposta."); }
        } else { toast.error("Erro a obter a chave da denuncia."); }
    }

    //EDITOR
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);

    const createMarkup = (html) => { return { __html: DOMPurify.sanitize(html) } }


    const openAttachment = (attachment) => {
        setLoading(true);
        var type = attachment?.name.split('1')[1];
        downloadFile(attachment.base64Image, attachment?.name, type === 'pdf' ? "'application/pdf'" : "'application/png'");
        setLoading(false);
    }

    const downloadFile = (base64String, fileName, contentType) => {
        const blob = b64toBlob(base64String, contentType);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) { byteNumbers[i] = slice.charCodeAt(i); }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }




    return (
        <>
            <section className="header relative pt-32 pb-32 items-center flex">
                <div className="container mx-auto items-center flex flex-wrap">
                    <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-1">
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                {Loading(loading)}
                                <form>
                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        Detalhes da denunicia - {report?.hashKey}
                                    </h6>

                                    <div className="text-right">
                                        <button
                                            className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={(e) => { e.preventDefault(); loadReport(report.hashKey); }}>
                                            Recarregar
                                        </button>
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-3/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Estado
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.status}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Tipo da denuncia
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.reportType}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Denunciante
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.whistleblower}
                                                />

                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Altura da denuncia
                                                </label>
                                                <input
                                                    type="email"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.occurenceDate}
                                                />

                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Regularidade
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.regularity}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Como obteve conhecimento
                                                </label>
                                                <input
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.awareness}
                                                />
                                            </div>
                                        </div>
                                        {report?.otherChanel !== "" &&

                                            <div className="w-full lg:w-3/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Comunicação da denuncia
                                                    </label>

                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={report?.otherChanel}
                                                        rows="2"
                                                    ></input>

                                                </div>
                                            </div>
                                        }

                                        <div className={report?.otherChanel !== "" ? "w-full lg:w-3/12 px-4" : "w-full lg:w-6/12 px-4"}>
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Anexos
                                                </label>
                                                {report?.attachment !== null && report?.attachment !== undefined ?
                                                    <button
                                                        className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150  w-full"
                                                        type="button"
                                                        onClick={(e) => { e.preventDefault(); openAttachment(report?.attachment); }}>
                                                        Download do Anexo
                                                    </button>
                                                    :
                                                    < input
                                                        disabled
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={"Sem anexo"} />
                                                }
                                            </div>
                                        </div>



                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Localização
                                                </label>

                                                <textarea
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    value={report?.location}
                                                    rows="2"
                                                ></textarea>

                                            </div>
                                        </div>
                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Descrição
                                                </label>
                                                <textarea
                                                    type="text"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    defaultValue={report?.description}
                                                    rows="2"
                                                ></textarea>
                                            </div>
                                        </div>
                                        {report?.name !== "" &&
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Nome
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={report?.name}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {report?.email !== "" &&
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={report?.email}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {report?.phone !== "" &&
                                            <div className="w-full lg:w-4/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Telefone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        defaultValue={report?.phone}
                                                    />

                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="w-full lg:w-12/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password">
                                                Data da denunica
                                            </label>
                                            <input
                                                type="datetime"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                value={report?.created}
                                            />

                                        </div>
                                    </div>

                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                        Desenvolvimento da denuncia
                                    </h6>

                                    {responses !== null && responses.length > 0 ?
                                        <div className="chat">
                                            {responses.map((response, index) => (
                                                <div
                                                    key={index}
                                                    data-time={response?.created}
                                                    className={`msg ${response.intervening === 1 ? 'sent' : 'rcvd'}`}
                                                    dangerouslySetInnerHTML={createMarkup(response.response)}
                                                >
                                                    {/*{response.response}*/}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <p>Não foi encontradas respostas á sua denuncia</p>
                                    }
                                    {closed === false &&
                                        <div className="w-full lg:w-12/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password">
                                                    Resposta
                                                </label>
                                                <Editor
                                                    editorStyle={{ border: '1px solid black' }}
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    wrapperClassName="wrapper-class"
                                                    editorClassName="editor-class"
                                                    toolbarClassName="toolbar-class"
                                                />
                                            </div>
                                        </div>
                                    }


                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ alignSelf: "flex-start" }}>
                                            <button
                                                className="bg-red-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={closeReport}>
                                                Fechar
                                            </button>
                                        </div>
                                        {closed === false &&
                                            <div style={{ alignSelf: "flex-end" }}>
                                                <button
                                                    className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={makeResponse}
                                                    disabled={loading}
                                                >
                                                    Submeter resposta
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    );

}