import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { GetHeader, HandleErrors, Loading } from "../../../utils/Utilities";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';


export default function DetailReport({ currentReport, color, closeDetails }) {
    const [report, setReport] = useState(null);
    const [responses, setResponses] = useState([]);
    var resposeReportClean = { hashKey: currentReport?.hashKey, response: "", status: "", closed: false }
    const [responseReport, setResponseReport] = useState(resposeReportClean);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState([]);
    const [closed, setclosed] = useState(false);

    useEffect(() => {
        load();
        GetStatus();
    }, [])

    const load = () => {
        if (localStorage.getItem("token") !== "" && currentReport != null) {
            GetReport(currentReport?.hashKey).then(response => {
                setReport(response != null ? response?.report : null);
                setResponses(response != null ? response?.responses : []);
                setclosed(response?.report?.closed === null ? true : false);
            });
        }
    }


    const GetStatus = () => {
        axios.get(global.api_url + "Report/GetStatus")
            .then((response) => {
                if (response.status === 200) {
                    setStatus(response.data);
                }
            }).catch(error => {
                HandleErrors(error)
            })
    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        // setResponseReport({ ...responseReport, [name]: value });

        if (value === "Concluído") {
            setResponseReport({ ...responseReport, [name]: value, closed: true });
        } else {
            setResponseReport({ ...responseReport, [name]: value, closed: false });
        }

        console.log(responseReport);
    }

    const GetReport = async (hashKey) => {
        setLoading(true);
        if (hashKey !== "" && hashKey !== null) {
            return axios.get(global.api_url + "Report/GetReport/" + hashKey)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success('Denuncia obtida com sucesso!', { position: 'top-right' });
                        setLoading(false);
                        return response.data;
                    }
                    setLoading(false);
                    return null;
                }).catch(error => {
                    HandleErrors(error);
                    setLoading(false);
                    return null;
                })
        } else {
            toast.error('Código inválido!.', { position: 'top-right' });
            return null;
        }
    }

    const onEditorStateChange = (editorState) => {
        setResponseReport({ ...responseReport, response: editorState });
    };

    const makeResponse = () => {
        setLoading(true);


        console.log(status);
        console.log(responseReport)

        if (responseReport?.status !== "") {
            if (responseReport?.hashKey !== "" && responseReport?.hashKey !== undefined) {
                if (convertedContent !== "<p></p>" && convertedContent !== undefined && convertedContent !== null) {
                    responseReport.response = convertedContent;
                    var headers = GetHeader();
                    axios.post(global.api_url + "Report/MakeAdminReportResponse", responseReport, { headers })
                        .then((response) => {
                            if (response.status === 200) {
                                var newobj = response.data;
                                setResponses([...responses, newobj]);
                                setResponseReport(resposeReportClean);
                                setConvertedContent(null);
                                setEditorState(EditorState.createEmpty());
                                toast.success('Submetida resposta com sucesso!', { position: 'top-right' });
                                load();
                            }
                            setLoading(false);
                        }).catch(error => {
                            setLoading(false);
                            HandleErrors(error);
                        })
                } else {
                    setLoading(false);
                    toast.error("É necessáro escrever uma resposta.");
                }
            } else {
                setLoading(false);
                toast.error("Erro a obter a chave da denuncia.");
            }
        } else {
            setLoading(false);
            toast.error("É necessário definir um estado.");
        }
    }


    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);

    useEffect(() => {
        let html = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(html);
    }, [editorState]);


    const createMarkup = (html) => { return { __html: DOMPurify.sanitize(html) } }


    const openAttachment = (attachment) => {
        setLoading(true);
        var type = attachment?.name.split('1')[1];
        downloadFile(attachment.base64Image, attachment?.name, type === 'pdf' ? "'application/pdf'" : "'application/png'");
        setLoading(false);
    }

    const downloadFile = (base64String, fileName, contentType) => {
        const blob = b64toBlob(base64String, contentType);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) { byteNumbers[i] = slice.charCodeAt(i); }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    return (
        <>
            {Loading(loading)}
            <div className="block w-full overflow-x-auto bg-blueGray-100 shadow-lg rounded-lg">
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Detalhes da denunicia - {report?.hashKey}
                        </h6>
                        <div className="text-right">
                            <button
                                className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={load}>
                                Recarregar
                            </button>
                        </div>

                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Estado
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.status}
                                    />

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Tipo da denuncia
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.reportType}
                                    />

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Denunciante
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.whistleblower}
                                    />

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Altura da denuncia
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.occurenceDate}
                                    />

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Regularidade
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.regularity}
                                    />

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Como obteve conhecimento
                                    </label>
                                    <input
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.awareness}
                                    />

                                </div>
                            </div>

                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Anexos
                                    </label>
                                    {/*<input*/}
                                    {/*    disabled*/}
                                    {/*    type="email"*/}
                                    {/*    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"*/}
                                    {/*    value={report?.attachment}*/}
                                    {/*/>*/}
                                    {report?.attachment !== null && report?.attachment !== undefined ?
                                        <button
                                            className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150  w-full"
                                            type="button"
                                            onClick={(e) => { e.preventDefault(); openAttachment(report?.attachment); }}>
                                            Download do Anexo
                                        </button>
                                        :
                                        < input
                                            disabled
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={"Sem anexo"} />
                                    }

                                </div>
                            </div>
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Data da denunica
                                    </label>
                                    <input
                                        disabled
                                        type="datetime"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.created}
                                    />

                                </div>
                            </div>

                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Localização
                                    </label>

                                    <textarea
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.location}
                                        rows="2"
                                    ></textarea>

                                </div>
                            </div>
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Descrição
                                    </label>

                                    <textarea
                                        disabled
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={report?.description}
                                        rows="2"
                                    ></textarea>
                                </div>
                            </div>

                            {report?.name !== "" &&
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            Nome
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={report?.name}
                                        />
                                    </div>
                                </div>
                            }
                            {report?.email !== "" &&
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            Email
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={report?.email}
                                        />

                                    </div>
                                </div>
                            }
                            {report?.phone !== "" &&
                                <div className="w-full lg:w-4/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            Telefone
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={report?.phone}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            Desenvolvimento da denuncia
                        </h6>

                        {responses !== null && responses.length > 0 ?
                            <div className="chat">
                                {responses.map((response, index) => (
                                    <div
                                        key={index}
                                        data-time={response.created}
                                        className={`msg ${response.intervening === 2 ? 'sent' : 'rcvd'}`}
                                        dangerouslySetInnerHTML={createMarkup(response.response)}
                                    >
                                        {/*{response.response}*/}
                                    </div>
                                ))}
                            </div>
                            :
                            <p>Não foi encontradas respostas á sua denuncia</p>
                        }

                        {closed === true &&

                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password">
                                            Estado
                                        </label>

                                        <div className="mt-2" style={{ marginTop: 10, marginBottom: 10 }}>
                                            <select
                                                id="status"
                                                name="status"
                                                onChange={handleChange}
                                                value={responseReport?.status}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option></option>
                                                {status.map((resp, index) => {
                                                    return (<option key={index}>{resp?.description}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password">
                                        Resposta
                                    </label>

                                    <Editor
                                        editorStyle={{ border: '1px solid black' }}
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                    />
                                </div>
                            </div>
                        }
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ alignSelf: "flex-start" }}>
                                <button
                                    className="bg-red-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={closeDetails}>
                                    Fechar
                                </button>
                            </div>
                            {closed === true &&
                                <div style={{ alignSelf: "flex-end" }}>
                                    <button
                                        className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={makeResponse}>
                                        Submeter resposta
                                    </button>
                                </div>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </>
    );
}