import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { GetHeader, HandleErrors, Loading } from "../../../utils/Utilities";
import DetailReport from "../../admin/Report/DetailReport";

export default function Table({ color }) {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [currentReport, setCurrentReport] = useState(null);
    const [status, setStatus] = useState([]);
    const [statusFilter, setStatusFilter] = useState((localStorage.getItem("filterStatus") !== "" && localStorage.getItem("filterStatus") !== undefined && localStorage.getItem("filterStatus") !== null) ? localStorage.getItem("filterStatus") : null);

    useEffect(() => {
        load()

    }, [])

    const load = () => {
        if (localStorage.getItem("token") !== "") {
            GetAllReports(statusFilter).then(response => {
                setReports(response);
            });
            GetStatus();
        }
    }

    const GetStatus = () => {
        axios.get(global.api_url + "Report/GetStatus")
            .then((response) => {
                if (response.status === 200) {
                    setStatus(response.data);
                }
            }).catch(error => {
                HandleErrors(error)
            })
    }

    const GetAllReports = async (filterStatus) => {
        setLoading(true);
        var headers = GetHeader();
        return axios.get(global.api_url + 'Report/GetAllReports' + (filterStatus !== "" && filterStatus !== undefined && filterStatus !== null ? "?filterStatus=" + filterStatus : ""), { headers })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    return response.data;
                }
                return [];
            }).catch(error => {
                setLoading(false);
                HandleErrors(error);
                return [];
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setStatusFilter(value);
        localStorage.setItem("filterStatus", value);
        GetAllReports(value).then(response => {
            setReports(response);
        });
    }


    const showDetails = (currentResport) => {
        setCurrentReport(currentResport);
    }

    const closeDetails = (bool) => {
        setCurrentReport(null);
        load();
    }


    return (
        <>
            {Loading(loading)}

            <div className={"relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " + (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")}>
                {currentReport !== null ?
                    <DetailReport currentReport={currentReport} color={color} closeDetails={closeDetails}></DetailReport>
                    : <>
                        <div className="rounded-t mb-0 px-4 py-3 border-0">
                            <div className="flex flex-wrap items-center">
                                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ alignSelf: "flex-start" }}>
                                            <h3
                                                className={
                                                    "font-semibold text-lg " +
                                                    (color === "light" ? "text-blueGray-700" : "text-white")
                                                }
                                            >
                                                Denuncias ativas
                                            </h3>
                                        </div>
                                        <div style={{ alignSelf: "flex-end" }}>
                                            <select
                                                id="status"
                                                name="status"
                                                onChange={handleChange}
                                                value={statusFilter}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option ></option>
                                                <option >Aberto</option>
                                                {status.map((resp, index) => {
                                                    return (<option key={index}>{resp?.description}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                        <div className="block w-full overflow-x-auto">

                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Domínio da denuncia
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Denunciante
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Estado
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Regularidade
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Criação
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        >
                                            Fechado
                                        </th>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                                (color === "light"
                                                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                                            }
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports != null && reports.map((report, index) => {
                                        return (
                                            <tr key={index}>
                                                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                                    <span className={"ml-3 font-bold " + (color === "light" ? "text-blueGray-600" : "text-white")}>
                                                        {report?.reportType}
                                                    </span>
                                                </th>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <span className={"ml-3 font-bold " + (color === "light" ? "text-blueGray-600" : "text-white")}>
                                                        {report?.whistleblower}
                                                    </span>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <span className={"ml-3 font-bold " + (color === "light" ? "text-blueGray-600" : "text-white")}>
                                                        {report?.status}
                                                    </span>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <span className={"ml-3 font-bold " + (color === "light" ? "text-blueGray-600" : "text-white")}>
                                                        {report?.regularity}
                                                    </span>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <span className={"ml-3 font-bold " + (color === "light" ? "text-blueGray-600" : "text-white")}>
                                                        {report?.created}
                                                    </span>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <span className={"ml-3 font-bold " + (color === "light" ? "text-blueGray-600" : "text-white")}>
                                                        {report?.closed}
                                                    </span>
                                                </td>
                                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                    {/*<TableDropdown />*/}

                                                    <div
                                                        className={"text-xs uppercase py-3 font-bold block "}
                                                        onClick={() => showDetails(report)}>
                                                        <i className={"fas fa-eye fa-2x mr-2"}></i>
                                                    </div>
                                                </td>
                                            </tr>);
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>
        </>
    );
}

Table.defaultProps = {
    color: "light",
};

Table.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};
