import React, { useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard/Dashboard.js";
import Settings from "views/admin/Settings.js";
import IndexReport from "views/admin/Report/IndexReport.js";

export default function Admin() {
    const [token] = useState(localStorage.getItem("token"));


    return (
        token ?
            <>
                <Sidebar />
                <div className="relative md:ml-64 bg-blueGray-100">
                    <AdminNavbar />
                    <HeaderStats />
                    <div className="px-4 md:px-10 mx-auto w-full -m-24">
                        <Switch>
                            <Route path="/admin/dashboard" exact component={Dashboard} />
                            <Route path="/admin/settings" exact component={Settings} />
                            <Route path="/admin/reports" exact component={IndexReport} />
                            <Redirect from="/admin" to="/admin/dashboard" />
                        </Switch>
                        <FooterAdmin />
                    </div>
                </div>
            </> :
            <>
                {window.location.replace(window.location.origin + '/auth/login')}
            </>

    );
}
