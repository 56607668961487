import { toast } from "react-hot-toast";
import { RingLoader } from "react-spinners";

export function GetSiteKeyReCAPTCHA() {
    return "6Ldn-JgmAAAAAL4tHlm-zSmuwxXv8s8_QC1g2yoR";
}

export function GetHeader() {
    return { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
}
export function GetHeaderPut() {
    return { 'Accept': '*/*', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')} ` };
}


export function HandleErrors(error) {

    switch (error?.status) {
        case 400:
            toast.error("Erro interno, caso o problema persista contactar Suporte tecnico.");
            break;
        case 401:
            toast.error("N�o tem permiss�es para aceder a esta �rea, fa�a login com outra conta.");
            break;
        case 403:
            toast.error("N�o tem permiss�es para aceder a esta �rea, fa�a login com outra conta.");
            break;
        case 404:
            toast.error("Erro interno, n�o foi poss�vel encontrar o m�todo.");
            break;
        default:
            toast.error(error.toString());
            break;
    }

}

export function Loading(visible) {
    return (visible === true ?
        (
            <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
                <RingLoader color={'rgba(227, 6, 19, 100)'} className="color-red-dp" css={'left: 50%; top: 50%; transform: translateX(-50%) border-color: #E2241A; flex: 1;margin-top:50px;'} size={75} />
            </div>
        ) :
        null)
}

