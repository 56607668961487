import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { GetSiteKeyReCAPTCHA, HandleErrors, Loading } from "../../../utils/Utilities";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-hot-toast";
import Pdf from '../../../attachment/PortalDenuncia.pdf';

export default function Report() {
    var cleanReport = { status: "Aberto", haskKey: "", reportType: "", whistleblower: "", alreadyComunicated: "", otherChanel: "", occurenceDate: "", location: "", regularity: "", awareness: "", description: "", attachment: null, name: "", email: "", phone: "" };
    const [report, setReport] = useState(cleanReport);
    const [response, setResponse] = useState(null);
    const [anonymous, setAnonymous] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reportType, setReportType] = useState([]);
    const [whistleblower, setWhistleblower] = useState([]);
    const [occurenceDate, setOccurenceDate] = useState([]);
    const [regularity, setRegularity] = useState([]);
    const [awareness, setAwareness] = useState([]);
    const captchaRef = useRef(null);
    const grecaptchaObject = window.grecaptcha;


    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "alreadyComunicated") {
            setReport({ ...report, [name]: value === "true" ? true : false });
        } else {
            setReport({ ...report, [name]: value });
        }
    }

    useEffect(() => {
        LoadData();
    }, [])


    const LoadData = () => {
        setLoading(true);
        GetReportType();
        GetWhistleblower();
        GetOccurenceDate();
        GetRegularity();
        GetAwareness();
        setLoading(false);
    }


    const GetReportType = () => {
        axios.get(global.api_url + "Report/GetReportType").then((response) => { if (response.status === 200) { setReportType(response.data); } }).catch(error => { HandleErrors(error) })
    }
    const GetWhistleblower = () => {
        axios.get(global.api_url + "Report/GetWhistleblower").then((response) => { if (response.status === 200) { setWhistleblower(response.data); } }).catch(error => { HandleErrors(error) })
    }
    const GetOccurenceDate = () => {
        axios.get(global.api_url + "Report/GetOccurenceDate").then((response) => { if (response.status === 200) { setOccurenceDate(response.data); } }).catch(error => { HandleErrors(error) })
    }
    const GetRegularity = () => {
        axios.get(global.api_url + "Report/GetRegularity").then((response) => { if (response.status === 200) { setRegularity(response.data); } }).catch(error => { HandleErrors(error) })
    }
    const GetAwareness = () => {
        axios.get(global.api_url + "Report/GetAwareness").then((response) => { if (response.status === 200) { setAwareness(response.data); } }).catch(error => { HandleErrors(error) })
    }





    const checkReport = () => {

        const token = captchaRef.current.getValue();
        if (token !== "" && token !== null && token !== undefined) {
            var errors = "";
            if (report !== null) {
                if (report.reportType === "") {
                    errors += " • Domino da denuncia tem ser preenchido.\n";
                }
                if (report.whistleblower === "") {
                    errors += " • Tipo de denunciante tem ser preenchido.\n";
                }

                if (report.alreadyComunicated === "") {
                    errors += " • Se já foi comunicado tem de ser preenchdido.\n";
                }

                if (report.alreadyComunicated === true) {
                    if (report.otherChanel === "") {
                        errors += " • Canal onde já foi comunicada a denunica tem ser preenchdido.\n";
                    }
                }


                if (report.occurenceDate === "") {
                    errors += " • Data da ocorrência tem ser preenchido.\n";
                }
                if (report.location === "") {
                    errors += " • Descrição da localização tem ser preenchido.\n";
                }
                if (report.description === "") {
                    errors += " • Descrição dos factos tem ser preenchido.\n";
                }
                if (anonymous === null) {
                    errors += " • Dados para contacto precisa ser selecionado.\n";
                }
                if (anonymous === false && report.name) {
                    errors += " • Name precisa de ser preenchido.\n";
                }
                if (anonymous === false && report.email) {
                    errors += " • Email precisa de ser preenchido.\n";
                }
                if (anonymous === false && report.phone) {
                    errors += " • Telefone precisa de ser preenchido.\n";
                }
                if (errors === "") {
                    captchaRef.current.reset();
                    return true;
                } else {
                    toast.error(errors, { position: "top-right" });
                    return false;
                }
            }
        } else {
            toast.error("ReCaptcha precisa ser verificada.", { position: "top-right" })
        }
    }

    const submitReport = (e) => {
        setLoading(true);
        if (checkReport() === true) {
            setLoading(true);
            axios.post(global.api_url + "Report/CreateReport", report)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response?.data);
                        setResponse(response?.data);
                        setReport(cleanReport);
                        //toast.success('Denuncia efetuada com sucesso.\nChave: ' + response?.data?.haskKey, { position: 'top-right' });
                        setLoading(false);
                    }
                    setLoading(false);
                }).catch(error => {
                    HandleErrors(error);
                    setLoading(false);
                })
        } else {
            setLoading(false);
        }

    }


    const cleanAll = () => {
        setResponse(null);
        setAnonymous(false);
        setReport(cleanReport);
    }

    const ReCAPTCHAerror = (error) => { console.log(error) }

    const handleChangeinput = (event) => {
        const reader = new FileReader();
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            reader.onload = () => {
                var image = {
                    name: file?.name,
                    base64Image: reader.result
                };
                setReport({ ...report, attachment: image });
            };
            reader.readAsDataURL(file);
        } else {
            toast.error("Não foi possível obter o anexo.");
        }

    };





    return (
        <>
            {Loading(loading)}
            <section className="header relative pt-32 pb-32 items-center flex">
                <div className="container mx-auto items-center flex flex-wrap">
                    <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12 px-4">

                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-1">
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    Formulário para Comunicação de Denúncias
                                </h6>

                                <h6 className="text-blueGray-600 text-xs font-bold mb-2">
                                    Ao abrigo da Lei 93/2021 de 20 de dezembro, a Leirilis – Acessórios e Peças para Automóveis, S.A. disponibiliza a todos os que se enquadrem no artigo 5.º da referida lei o presente canal de denúncia.
                                    <br></br>
                                    Para o efeito, a decisão de denúncia deve ser tomada de forma consciente, ponderada, honesta e feita de boa-fé. Pois, a contrario, a utilização deliberada e sem fundamento do presente canal poderá constituir infração disciplinar, civil ou criminal.
                                    <br></br>
                                    Para mais informações consulte a <a href={Pdf} target="_blank">Política de Comunicação de Denúncias</a>.
                                    <br></br>
                                    Para efetuar a sua comunicação de infração, preencha o formulário abaixo.
                                </h6>

                                {response === null ?

                                    <form>
                                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                            Informações
                                        </h6>
                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Domínio da denuncia*
                                                    </label>


                                                    <div className="mt-2">
                                                        <select
                                                            disabled={loading}
                                                            id="reportType"
                                                            name="reportType"
                                                            onChange={handleChange}
                                                            value={report?.reportType}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option value=""></option>

                                                            {reportType.map((aux, index) => {
                                                                return (<option key={index}>{aux?.description}</option>)
                                                            })}

                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Denunciante*
                                                    </label>

                                                    <div className="mt-2">
                                                        <select
                                                            disabled={loading}
                                                            id="whistleblower"
                                                            name="whistleblower"
                                                            onChange={handleChange}
                                                            value={report?.whistleblower}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option value=""></option>
                                                            {whistleblower.map((aux, index) => {
                                                                return (<option key={index}>{aux?.description}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Ocorrência já foi comunicada previamente através de outro canal?*
                                                    </label>

                                                    <div className="mt-2">
                                                        <select
                                                            disabled={loading}
                                                            id="alreadyComunicated"
                                                            name="alreadyComunicated"
                                                            onChange={handleChange}
                                                            value={report?.alreadyComunicated}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option value=""></option>
                                                            <option value={true} >Sim</option>
                                                            <option value={false}>Não</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>


                                            {report?.alreadyComunicated === true

                                                &&
                                                <div className="w-full lg:w-12/12 px-4">
                                                    <div className="relative w-full mb-3">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password">
                                                            Local onde a denunica já foi comunicada*
                                                        </label>

                                                        <div className="mt-2">
                                                            <input
                                                                disabled={loading}
                                                                type="text"
                                                                id="otherChanel"
                                                                name="otherChanel"
                                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                value={report?.otherChanel}
                                                                onChange={handleChange}
                                                            ></input>
                                                        </div>

                                                    </div>
                                                </div>

                                            }

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Data da ocorrência*
                                                    </label>

                                                    <div className="mt-2">
                                                        <select
                                                            disabled={loading}
                                                            id="occurenceDate"
                                                            name="occurenceDate"
                                                            onChange={handleChange}
                                                            value={report?.occurenceDate}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option value=""></option>
                                                            {occurenceDate.map((aux, index) => {
                                                                return (<option key={index}>{aux?.description}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Local da ocorrência*
                                                    </label>
                                                    <textarea
                                                        disabled={loading}
                                                        type="text"
                                                        id="location"
                                                        name="location"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={report?.location}
                                                        onChange={handleChange}
                                                        placeholder="Indicar o local e detalhes que possam ajudar na identificação."
                                                        rows="4"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Regularidade da ocorrência*
                                                    </label>

                                                    <div className="mt-2">
                                                        <select
                                                            disabled={loading}
                                                            id="regularity"
                                                            name="regularity"
                                                            onChange={handleChange}
                                                            value={report?.regularity}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option value=""></option>
                                                            {regularity.map((aux, index) => {
                                                                return (<option key={index}>{aux?.description}</option>)
                                                            })}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Como obteve conhecimento da situação*
                                                    </label>


                                                    <div className="mt-2">
                                                        <select
                                                            disabled={loading}
                                                            id="awareness"
                                                            name="awareness"
                                                            onChange={handleChange}
                                                            value={report?.awareness}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                            <option value=""></option>
                                                            {awareness.map((aux, index) => {
                                                                return (<option key={index}>{aux?.description}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Descrição da denuncia*
                                                    </label>
                                                    <textarea
                                                        disabled={loading}
                                                        type="text"
                                                        id="description"
                                                        name="description"
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={report?.description}
                                                        onChange={handleChange}
                                                        placeholder="A descrição deve ser concisa, enumerar factos relevantes e referir nomes dos internientes."
                                                        rows="4"
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Anexar ficheiro
                                                    </label>

                                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                        <div className="text-center">
                                                            <svg className="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                                                                <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
                                                            </svg>
                                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                                <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                                                    <span>Selecionar ficheiro</span>
                                                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" accept=".jpg,.jpeg,.png,.gif,.pdf" onChange={handleChangeinput} />
                                                                </label>
                                                                <br></br>
                                                            </div>


                                                            {report?.attachment !== null && (
                                                                <div>
                                                                    <p>Ficheiro selecionado:</p>
                                                                    <p>{report?.attachment?.name}</p>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">
                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Dados para contacto*
                                                    </label>


                                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                                        <input
                                                            disabled={loading}
                                                            className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                                            type="radio"
                                                            name="anonymous"
                                                            value={anonymous}
                                                            onChange={() => setAnonymous(false)}
                                                            id="anonymous01" />
                                                        <label
                                                            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                                            htmlFor="anonymous01">
                                                            Pretendo manter anonimato
                                                        </label>
                                                    </div>
                                                    <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                                                        <input
                                                            disabled={loading}
                                                            className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                                                            type="radio"
                                                            name="anonymous"
                                                            value={anonymous}
                                                            onChange={() => setAnonymous(true)}
                                                            id="anonymous02"
                                                        />
                                                        <label
                                                            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                                            htmlFor="anonymous02">
                                                            Posso ser contactado
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {anonymous === true &&
                                                <>
                                                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                                                    <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                                        Dados de contacto
                                                    </h6>

                                                    <div className="w-full lg:w-12/12 px-4">
                                                        <div className="relative w-full mb-3">
                                                            <label
                                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                                htmlFor="grid-password">
                                                                Nome
                                                            </label>
                                                            <input
                                                                disabled={loading}
                                                                id="name"
                                                                name="name"
                                                                onChange={handleChange}
                                                                value={report?.name}
                                                                type="text"
                                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-full lg:w-12/12 px-4">
                                                        <div className="relative w-full mb-3">
                                                            <label
                                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                                htmlFor="grid-password"
                                                            >
                                                                Email
                                                            </label>
                                                            <input
                                                                id="email"
                                                                name="email"
                                                                onChange={handleChange}
                                                                value={report?.email}
                                                                disabled={loading}
                                                                type="email"
                                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                defaultValue=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-full lg:w-12/12 px-4">
                                                        <div className="relative w-full mb-3">
                                                            <label
                                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                                htmlFor="grid-password">
                                                                Telefone
                                                            </label>
                                                            <input
                                                                id="phone"
                                                                name="phone"
                                                                onChange={handleChange}
                                                                value={report?.phone}
                                                                disabled={loading}
                                                                type="number"
                                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                                defaultValue=""
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>

                                        <div className="w-full lg:w-12/12 px-4">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                <ReCAPTCHA
                                                    sitekey={GetSiteKeyReCAPTCHA()}
                                                    ref={captchaRef}
                                                    onErrored={ReCAPTCHAerror}
                                                    grecaptcha={grecaptchaObject}
                                                />
                                            </div>
                                        </div>
                                        <br></br>
                                        {loading === false &&
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                    <button
                                                        className="bg-blue-dp text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={submitReport}
                                                        disabled={loading}
                                                    >
                                                        Submeter denuncia
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        <h6> * Todos os dados devem ser preenchidos.</h6>
                                    </form>
                                    : <>
                                        <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                            Denuncia efetuada com sucesso!
                                        </h6>
                                        <div className="flex flex-wrap">
                                            <div className="w-full lg:w-12/12 px-4">
                                                <div className="relative w-full mb-3">


                                                    <h6 className="text-blueGray-600 text-xs font-bold mb-2">
                                                        A chave abaixo, deve ser guardada pelo utilizador, não sendo possível recuperá-la posteriormente.
                                                        <br></br>
                                                    </h6>

                                                    <label
                                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                        htmlFor="grid-password">
                                                        Chave de acesso a denuncia <br></br> - A chave deve ser guardada pelo utilizador, não sendo possível recuperá-la posteriormente:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={response?.hashKey}
                                                    />

                                                    <div style={{ marginTop: 20 }} className="text-right">
                                                        <button
                                                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={cleanAll}
                                                        >
                                                            Fechar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
