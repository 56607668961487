/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Home from "./home";

export default function Index() {
    return (
        <>
            <IndexNavbar fixed />
            <Home />
            <Footer />
        </>
    );
}
