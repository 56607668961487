import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts
import IndexHome from "views/pages/Index/Index.js";
import ReportHome from "views/pages/Report/Index.js";
import ConsultReport from "views/pages/ConsultReport/Index.js";
global.api_url = (window.location.href.startsWith("http://localhost") || window.location.href.startsWith("http://127.0.0.1")) ? "https://api.dpautomotive.pt/" : "https://api.leirilis.pt/";



ReactDOM.render(
    <BrowserRouter>
        <Toaster />
        <Switch>
            {/* add routes with layouts */}
            <Route path="/admin" component={Admin} />
            <Route path="/auth" component={Auth} />
            {/* add routes without layouts */}
            {/*<Route path="/landing" exact component={Landing} />*/}
            {/*<Route path="/profile" exact component={Profile} />*/}
            <Route path="/complaint" exact component={ReportHome} />
            <Route path="/consult-report" exact component={ConsultReport} />




            <Route path="/" exact component={IndexHome} />
            <Redirect from="*" to="/" />
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
